import { ArrowForward } from '@mui/icons-material';
import { Box, BoxProps, Stack } from '@mui/material';
import { memo, useState } from 'react';
import { useSelector } from 'react-redux';
import { myHistory } from '../../common/base/myHistory';
import { CButton } from '../../common/components/CButton';
import { CLanguageSelect } from '../../common/components/CLanguageSelect';
import { lang, langM } from '../../common/config/lang';
import { RootStateType } from '../../common/reducer/RootReducer';
import { MySystem } from '../../common/util/MySystem';
import { WSubTitle } from '../components/WTitle';

export const WPagePortal = memo((props: BoxProps) => {
   const [close, setClose] = useState(false);
   useSelector((state: RootStateType) => state.ui.forceUpdate);
   return (
      <Stack
         sx={{
            height: '100%',
            margin: 0,
            padding: 0,
            background: 'linear-gradient(to bottom, #bbddff 0px ,#fff 200px)',
         }}
      >
         <Stack
            sx={{
               flex: 1,
               overflow: 'hidden',
               overflowY: 'auto',
               textAlign: 'center',
               height: '100%',
               width: '100%',
               alignItems: 'center',
            }}
         >
            <Stack width={'100%'} direction={'row'} justifyContent={'end'}>
               <CLanguageSelect />
            </Stack>
            <Box p={1} />

            <Box
               sx={{
                  // maxWidth: 900,
                  width: '100%',
                  maxWidth: 1000,
                  display: 'flex',
                  flexWrap: 'wrap',
                  flexDirection: 'row',
                  justifyContent: 'center',
               }}
            >
               {/* 파인드포 */}
               <Stack
                  sx={{
                     width: 340,
                     height: 270,
                     direction: 'column',
                     justifyContent: 'center',
                     alignContent: 'center',
                     borderRadius: 5,
                     boxShadow: '0px 1px 2px 1px rgba(0, 0, 0, 0.3)',
                     // maxwidth: 340,
                     m: 1,
                     p: 2.5,
                  }}
               >
                  <Box>
                     <img
                        className="zoomInY"
                        src={`/findfour_h.jpg`}
                        alt={lang.w.제목}
                        width={300}
                        style={{
                           maxWidth: '100%',
                        }}
                     />
                  </Box>
                  <Box className={'zoomIn'} m={1.5}>
                     {lang.etc.파인드포설명}
                  </Box>
                  <Box p={1}>
                     <CButton
                        // className="heartBeat"
                        startIcon={<ArrowForward />}
                        onClick={() => {
                           window.open('https://findfour.framer.ai/');
                        }}
                     >
                        {lang.common.시작하기}
                     </CButton>
                  </Box>
               </Stack>

               {/* 마이월드메이커 */}
               <Stack
                  sx={{
                     width: 340,
                     height: 270,
                     direction: 'column',
                     justifyContent: 'center',
                     alignContent: 'center',
                     borderRadius: 5,
                     boxShadow: '0px 1px 2px 1px rgba(0, 0, 0, 0.3)',
                     // maxwidth: 340,
                     m: 1,
                     p: 2.5,
                  }}
               >
                  <Box>
                     <img
                        className="zoomInY"
                        src={`/logo2_1024x238_${langM.GetCurrentLanguage()}.png`}
                        alt={lang.w.제목}
                        width={300}
                        style={{
                           maxWidth: '100%',
                        }}
                     />
                  </Box>
                  <WSubTitle sx={{ fontSize: '1em', p: 0 }}>{lang.w.개요.toUpperCase()}</WSubTitle>
                  <Box p={0.5} />
                  <Box className={'zoomIn'} m={1.5}>
                     {lang.w.어바웃01}
                  </Box>
                  <Box p={1}>
                     <CButton
                        // className="heartBeat"
                        startIcon={<ArrowForward />}
                        onClick={() => {
                           window.open('https://myworldmaker.com/home');
                        }}
                     >
                        {lang.common.시작하기}
                     </CButton>
                  </Box>
               </Stack>

               {/* 좀비슈터 */}
               <Stack
                  sx={{
                     width: 340,
                     height: 270,
                     direction: 'column',
                     justifyContent: 'center',
                     alignContent: 'center',
                     borderRadius: 5,
                     boxShadow: '0px 1px 2px 1px rgba(0, 0, 0, 0.3)',
                     // maxwidth: 340,
                     m: 1,
                     p: 2.5,
                  }}
               >
                  <Box>
                     <img
                        className="zoomInY"
                        src={`/zombie_shooter_h.png`}
                        alt={'zombieshooter'}
                        width={230}
                        style={{
                           maxWidth: '100%',
                        }}
                     />
                  </Box>
                  <Box className={'zoomIn'} m={1.5}>
                     {lang.etc.좀비슈터설명}
                  </Box>
                  <Box p={1}>
                     <CButton
                        disabled={true}
                        // className="heartBeat"
                        startIcon={<ArrowForward />}
                        onClick={() => {
                           window.open('https://findfour.framer.ai/');
                        }}
                     >
                        {lang.common.준비중}
                     </CButton>
                  </Box>
               </Stack>

               {/* 이미지 생성기 */}
               <Stack
                  sx={{
                     width: 340,
                     height: 270,
                     direction: 'column',
                     justifyContent: 'center',
                     alignContent: 'center',
                     borderRadius: 5,
                     boxShadow: '0px 1px 2px 1px rgba(0, 0, 0, 0.3)',
                     // maxwidth: 340,
                     m: 1,
                     p: 2.5,
                  }}
               >
                  <Box>
                     <img
                        className="zoomInY"
                        src={`/ai_h.jpg`}
                        alt={'ai'}
                        width={150}
                        style={{
                           maxWidth: '100%',
                        }}
                     />
                  </Box>
                  <Box className={'zoomIn'} m={1.5}>
                     {lang.etc.AI이미지생성기}
                  </Box>
                  <Box p={1}>
                     <CButton
                        disabled={true}
                        // className="heartBeat"
                        startIcon={<ArrowForward />}
                        onClick={() => {
                           window.open('https://findfour.framer.ai/');
                        }}
                     >
                        {lang.common.준비중}
                     </CButton>
                  </Box>
               </Stack>
            </Box>

            <Box fontSize={'0.8em'} m={1}>
               <Box>
                  <img
                     height={30}
                     src={'/logo2_256x128.png'}
                     alt="logo"
                     style={{ position: 'relative', top: 1, margin: 5 }}
                  />
               </Box>
               <Box display={'inline-block'}>
                  {lang.common.문의} : <a href="mailto:contact@myworldmaker.com">contact@myworldmaker.com</a>
               </Box>
               {/* <Box display={'inline-block'} ml={1} mr={1}>
                     |
                  </Box>
                  <Box display={'inline-block'}>
                     <a
                        href="https://docs.google.com/presentation/d/1VI8Bu03JTOVzuYOnfUQb8dNYYH4nhYM1siWXvsP2dAE/edit?usp=sharing"
                        target="_blank"
                        rel="noopener noreferrer"
                     >
                        {lang.common.사업계획서}
                     </a>
                  </Box> */}
               <Box width={'auto'}>{lang.w.주소}</Box>
               <Box width={'auto'}>{lang.w.사업자등록번호}</Box>
            </Box>
         </Stack>
      </Stack>
   );
});
