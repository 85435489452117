import { memo, useState } from 'react';
import { useSelector } from 'react-redux';

import { Flight } from '@mui/icons-material';
import { Box, BoxProps, Stack } from '@mui/material';

import { myHistory } from '../../common/base/myHistory';
import { CButton } from '../../common/components/CButton';
import { lang, langM } from '../../common/config/lang';
import { RootStateType } from '../../common/reducer/RootReducer';
import { MySystem } from '../../common/util/MySystem';
import { WAppBar } from '../components/WAppBar';
import { WSubTitle } from '../components/WTitle';

export const WPageAbout = memo((props: BoxProps) => {
   const [close, setClose] = useState(false);
   useSelector((state: RootStateType) => state.ui.forceUpdate);
   return (
      <Stack
         className={close ? 'toLeft' : 'fromLeft'}
         direction={'column'}
         height={'100%'}
         sx={{
            zIndex: 1,
            position: 'fixed',
         }}
      >
         <Box
            sx={{
               flex: 1,
               overflow: 'hidden',
               overflowY: 'auto',
               textAlign: 'center',
               height: '100%',
               width: '100vw',
               background: 'linear-gradient(to bottom, #bbddff 0px ,#fff 200px)',
            }}
         >
            <Box>
               <WAppBar />
               {/* 제목 */}
               <Box p={1} />
               <img
                  className="zoomInY"
                  src={`/logo2_1024x238_${langM.GetCurrentLanguage()}.png`}
                  alt={lang.w.제목}
                  width={300}
                  style={{
                     paddingLeft: 15,
                     paddingRight: 15,
                     maxWidth: '100%',
                  }}
               />
               <WSubTitle sx={{ fontSize: '1em', p: 0 }}>{lang.w.개요.toUpperCase()}</WSubTitle>
               <Box p={1} />
               <Box p={1}>
                  <CButton
                     className="heartBeat"
                     startIcon={<Flight />}
                     onClick={() => {
                        setClose(true);
                        setTimeout(() => {
                           myHistory.push('/home');
                           MySystem.ForceUpdate();
                        }, 500);
                     }}
                  >
                     {lang.common.시작하기}
                  </CButton>
               </Box>
               <Stack p={1} justifyContent={'center'} alignItems={'center'}>
                  <Box sx={{ textAlign: 'center' }} maxWidth={500}>
                     <Box display={'inline-block'} textAlign={'left'}>
                        <Box className={'fromLeft'} m={1.5}>
                           {lang.w.어바웃01}
                        </Box>
                        <Box className={'fromLeft'} m={1.5}>
                           {lang.w.어바웃02}
                        </Box>
                        <Box className={'fromLeft'} m={1.5}>
                           {lang.w.어바웃03}
                        </Box>
                        {/* <Box m={1.5}>{lang.w.어바웃04}</Box>
               <Box m={1.5}>{lang.w.어바웃05}</Box> */}
                     </Box>
                     {/* 유튜브 영상 */}
                     <Box m={0.5} display={'block'}>
                        <iframe
                           style={{ maxWidth: '100%' }}
                           // src="https://www.youtube.com/embed/-BrgVHK1OVo?autoplay=1&mute=1"
                           src="https://www.youtube.com/embed/OWVyum49cSA"
                           frameBorder={0}
                           width="430"
                           height="240"
                           title="YouTube video player"
                           allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                           allowFullScreen
                        ></iframe>
                     </Box>
                     <Box display={'block'} textAlign={'left'}>
                        {/* <Box m={1.5}>{lang.w.어바웃01}</Box>
               <Box m={1.5}>{lang.w.어바웃02}</Box>
               <Box m={1.5}>{lang.w.어바웃03}</Box> */}
                        <Box className={'fromRight'} m={1.5}>
                           {lang.w.어바웃04}
                        </Box>
                        <Box className={'fromRight'} m={1.5}>
                           {lang.w.어바웃05}
                        </Box>
                     </Box>
                     <Box m={0.5} display={'block'}>
                        <iframe
                           style={{ maxWidth: '100%' }}
                           // src="https://www.youtube.com/embed/-BrgVHK1OVo?autoplay=1&mute=1"
                           src="https://www.youtube.com/embed/s2MuFQTg5xA"
                           frameBorder={0}
                           width="430"
                           height="240"
                           title="YouTube video player"
                           allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                           allowFullScreen
                        ></iframe>
                     </Box>
                  </Box>
               </Stack>
               <Box fontSize={'0.8em'} m={1}>
                  <Box>
                     <img
                        height={30}
                        src={'/logo2_256x128.png'}
                        alt="logo"
                        style={{ position: 'relative', top: 1, margin: 5 }}
                     />
                  </Box>
                  <Box display={'inline-block'}>
                     {lang.common.문의} : <a href="mailto:contact@myworldmaker.com">contact@myworldmaker.com</a>
                  </Box>
                  {/* <Box display={'inline-block'} ml={1} mr={1}>
                     |
                  </Box>
                  <Box display={'inline-block'}>
                     <a
                        href="https://docs.google.com/presentation/d/1VI8Bu03JTOVzuYOnfUQb8dNYYH4nhYM1siWXvsP2dAE/edit?usp=sharing"
                        target="_blank"
                        rel="noopener noreferrer"
                     >
                        {lang.common.사업계획서}
                     </a>
                  </Box> */}
                  <Box width={'auto'}>
                     <Box display={'inline-block'} bgcolor={'white'}>
                        {lang.w.주소}
                     </Box>
                  </Box>
               </Box>
            </Box>
         </Box>
      </Stack>
   );
});
